export const zh_CN = {
  Bussiness_Introduction: '业务介绍',
  Real_Time_Exchange_Rate: '实时汇率',
  Contact_Us: '联系我们',
  News: '最新资讯',
  About_Us: '关于我们',
  Service: '业务介绍',
  Rate: '实时汇率',
  About_KUNDAX: '企业介绍',
  Our_Advantage: '我们的优势',
  Anti_Money_Laundering_Policy: '反洗钱政策',
  Disclaimer: '免责声明',
  Kundax_Honor: '企业荣誉',
  Currency_Exchange: '外币汇兑',
  International_Remittance: '国际汇款',
  ONLINE_REGISTRATION: '在线开户',
  FAQS: '常见问题',
  Currency_Pair: '交易币比对',
  Sell_Cash: '现钞买入价',
  Buy_Cash: '现钞卖出价',
  Sell_TT: '现汇买入价',
  Buy_TT: '现汇卖出价',
  Company_News: '公司要闻',
  Market_Insight: '汇市动态'
}
